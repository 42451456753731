.frm-wrap {
    background-color: #faf9f9;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 1);
    box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 1);
}

.frm-wrap .form-group {
    margin-bottom: 25px;
}

.frm-wrap label {
    font-size: 15px;
    color: #333;
    padding-bottom: 10px;
    display: block;
    width: 100%;
}

.frm-wrap .form-control {
    width: 100%;
    height: 40px;
    border: #949393 solid 1px;
    border-radius: 5px;
    font-size: 15px;
    background-color: #faf9f9;
}

.frm-wrap textarea.form-control {
    width: 100%;
    height: 100px;
    resize: none;
    border: #949393 solid 1px;
    border-radius: 5px;
    font-size: 15px;
}

label.customradio {
    display: inline-block;
    width: fit-content;
    margin-right: 20px;
    font-size: 15px;
    color: #333;
}

.frm-wrap input[type="radio"] {
    position: relative;
    top: 2px;
    margin-left: 3px;
}

.frm-wrap button {
    background-color: #d82533;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    text-transform: uppercase;
    border: #d82533 solid 2px;
}

.frm-wrap button:hover {
    background-color: transparent;
    color: #d82533;
}

.contact-slide .owl-nav .owl-next {
    position: absolute;
    top: 40%;
    right: 20px;
}

.contact-slide .owl-nav .owl-next span {
    font-size: 0;
    display: inline-block;
    /* background-image: url(../images/arrow-right-red.png); */
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

.contact-slide .owl-nav .owl-prev {
    position: absolute;
    top: 40%;
    left: 20px;
}

.contact-slide .owl-nav .owl-prev span {
    font-size: 0;
    display: inline-block;
    /* background-image: url(../images/arrow-left-red.png); */
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

.contact-slide .slide-box {
    margin: 0px;
}

.contact-slide .slide-box .slide-pic img {
    border-radius: 20px;
    height: 300px;
    object-fit: cover;
}

.frm-wrap .owl-nav .owl-next {
    position: absolute;
    top: 30%;
    right: 5px;
}

.frm-wrap .owl-nav .owl-next span {
    font-size: 0;
    display: inline-block;
    /* background-image: url(../images/arrow-right-red.png); */
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}
input {
    margin-bottom: 5px !important;
    text-align: left !important;
}

textarea {
    text-align: left !important;
}

.frm-wrap .owl-nav .owl-prev {
    position: absolute;
    top: 30%;
    left: 5px;
}

.frm-wrap .owl-nav .owl-prev span {
    font-size: 0;
    display: inline-block;
    /* background-image: url(../images/arrow-left-red.png); */
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

.frm-wrap .slide-box {
    margin: 0px 10px;
}

/* .frm-wrap .slide-box .slide-pic img {} */

.down-btn {
    margin-top: 50px;
}

.down-btn a {
    display: inline-block;
    background-color: #d82533;
    color: #fff;
    padding: 12px 50px;
    border-radius: 5px;
    text-transform: uppercase;
    border: #d82533 solid 2px;
}

.down-btn a:hover {
    background-color: transparent;
    color: #d82533;
}

.down-txt {
    padding-top: 30px;
    font-size: 15px;
    color: #333;
}

.down-txt a {
    display: inline-block;
    color: #d82533;
}

.input-group.date {
    position: relative;
}

.input-group-addon {
    position: absolute;
    right: 1px;
    top: 1px;
    height: 38px;
    line-height: 40px;
    width: 40px;
    background-color: #f5f5f5;
    text-align: center;
    border-left: #ddd solid 1px;
    z-index: 9;
    border-radius: 0px 5px 5px 0px;
}