.application-form-container {
    background-color: #fff;
}

input, select, textarea {
    background-color: #EFF1F999 !important;
    padding: 10px 16px !important;
    color: black !important;
    border: none !important;
}

input:focus input:active {
    border: none !important;
}

textarea {
    height: 120px;
    margin-bottom: 0 !important;
}

select {
    width: 100%;
}

label {
    color: #000 !important;
    margin-bottom: 10px;
    font-weight: 600;
}

.next-button {
    background-color: #5570F1;
    border-radius: 14px;
    padding: 12px;
    width: 140px;
    color: #fff !important;
    font-weight: 600;
}

.cancel-button {
    background-color: #fff;
    border: 2px solid #5570F1;
    border-radius: 14px;
    padding: 12px;
    width: 140px;
    color: #5570F1 !important;
    font-weight: 600;
}